import React from 'react'
import Header from '../Layout/AdminDashboardHeader';
import { Link, Outlet } from 'react-router-dom';

const SharedLayoutDashboard = () => {
  return (
    <>
      <Header />
      {/* <Outlet /> */}
      {/* <Footer /> */}
    </>
  )
}

export default SharedLayoutDashboard