import React from 'react'
import Header from '../Layout/CompanyDashboardHeader';

const SharedLayoutDashboard = () => {
  return (
    <>
      <Header />
    </>
  )
}

export default SharedLayoutDashboard