import React from 'react'
import Header from '../Layout/DashboardHeader';
// import TopBar from '../Layout/TopBar';

  //1

const SharedLayoutDashboard = () => {
  return (
    <>
        {/* <TopBar  /> */}
      <Header />
    </>
  )
}

export default SharedLayoutDashboard